export const Sts = {
    MATCH_NONE: 0,
    MATCH_TOO_MANY: 1,
    MATCH_ONE_PROCESSING: 2,
    MATCH_ONE_SUCCESS: 3,
    MATCH_ONE_FAIL: 4,
};

export default {
    Sts,
};
import DefSms from '../constants/DefSms';
import DefScdb from '../constants/DefScdb';

const AutoCallbackType = {
  SMS: 0,
  SCDB: 1,
};

let autoCallbackMixins = {
  data: () => ({
    SmsSts: DefSms.Sts,
    ScdbSts: DefScdb.Sts,
    AutoCallbackType,
  }),
  methods: {
    smsStsIsSuccessMatch(sts) {
        return sts === DefSms.Sts.MATCH_ONE_SUCCESS;
    },
    smsStsCanManualMatch(sts) {
      return (
        sts === DefSms.Sts.MATCH_NONE ||
        sts === DefSms.Sts.MATCH_TOO_MANY ||
        sts === DefSms.Sts.MATCH_ONE_FAIL
      );
    },
    scdbStsIsSuccessMatch(sts) {
      return sts === DefScdb.Sts.MATCH_ONE_SUCCESS;
    },
    scdbStsCanManualMatch(sts) {
      return (
        sts === DefScdb.Sts.MATCH_NONE ||
        sts === DefScdb.Sts.MATCH_TOO_MANY ||
        sts === DefScdb.Sts.MATCH_ONE_FAIL
      );
    },
  },
}

export default autoCallbackMixins;

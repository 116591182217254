<template>
  <v-container fluid class="pa-4">
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>
          {{$t(curPageTitle)}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-slot:extension>
          <v-tabs
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="white"></v-tabs-slider>
            <v-tab
              v-for="(item, index) in items" :to="item.route" :key="index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <div>
        <v-card-title>
          {{$t(curPageTitle)}}
          <v-spacer></v-spacer>
          <v-row v-if="false">
            <v-col>
              <v-datetime-picker
                v-model="fromDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_from')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="fetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col>
              <v-datetime-picker
                v-model="toDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_to')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="fetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                @click:append="fetch()"
                @keydown.enter="fetch()"
                :label="$t('search')"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="dataList"
          :page="page"
          :pageCount="numberOfPages"
          :options.sync="options"
          :must-sort="false"
          :footer-props="{
            'show-current-page': true,
            'items-per-page-options': rowsPerPageItem,
            'show-first-last-page': true,
          }"
          :server-items-length="totalData"
          :loading="loading"
        >
          <template v-slot:footer.prepend>
            <validation-observer 
              ref="pageInputObserver" 
              v-slot="{ validate, reset }"
            >
              <validation-provider 
                name="page" 
                :rules="{integer: true, min_value: 1, max_value: numberOfPages}" 
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model.number="pageInput"
                  type="number"
                  append-outer-icon="mdi-chevron-right-circle-outline"
                  @click:append-outer="jumpToPage"
                  :label="$t('Go to page')"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </validation-observer>
          </template>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <manual-match-form 
                :edited-item-type="editedItem && editedItem._type"
                :edited-item="editedItem"
                :dialog="dialog"
                @success="successManualMatch"
                @close="closeManualMatch"
              />
            </v-dialog>
          </template>

          <!-- Common -->

          <template v-slot:item.amount="{ item }">
            {{ 
              item.amount 
                ? item.amount.toLocaleString(
                    undefined, {minimumFractionDigits: 2}
                  ) 
                : '-'
            }}
          </template>
          <template v-slot:item.cmp_transaction_number="{ item }">
            {{
              item._type === AutoCallbackType.SMS && 
              smsStsIsSuccessMatch(item.cmp_sts) &&
              item.cmp_transaction_number !== undefined &&
              item.cmp_transaction_number !== null
                ? item.cmp_transaction_number
                : ''
            }}
            {{
              item._type === AutoCallbackType.SCDB && 
              scdbStsIsSuccessMatch(item.cmp_sts) &&
              item.cmp_transaction_number !== undefined &&
              item.cmp_transaction_number !== null
                ? item.cmp_transaction_number
                : ''
            }}
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              v-if="
                item._type === AutoCallbackType.SMS && 
                isCsAgent && 
                smsStsCanManualMatch(item.cmp_sts)
              "
              small
              class="mr-2"
              @click="viewItem(item._type, item.sms_id)"
            >
              mdi-invoice-arrow-right
            </v-icon>
            <v-icon
              v-if="
                item._type === AutoCallbackType.SCDB && 
                isCsAgent && 
                scdbStsCanManualMatch(item.cmp_sts)
              "
              small
              class="mr-2"
              @click="viewItem(item._type, item.db_id)"
            >
              mdi-invoice-arrow-right
            </v-icon>
          </template>

          <!-- SMS -->

          <template v-if="isSms" v-slot:item.sms_time="{ item }">
            {{ new Date(item.sms_time).toLocaleString() }}
          </template>

          <!-- SCDB -->

          <template v-if="isDb" v-slot:item.trans_time="{ item }">
            {{ new Date(item.trans_time).toLocaleString() }}
          </template>

          <template v-slot:no-data>
            <v-btn color="primary" @click="fetch()">{{$t('refresh')}}</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import transactionMixins from '../../mixins/transactionMixins';
  import globalMixins from '../../mixins/globalMixins';
  import currencyMixins from '../../mixins/currencyMixins'
  import autoCallbackMixins from '../../mixins/autoCallbackMixins'
  import { integer, min_value, max_value } from 'vee-validate/dist/rules';
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import ManualMatchForm from './ManualMatchForm';

  extend('integer', integer);
  extend('min_value', min_value);
  extend('max_value', max_value);

  export default {
    name: 'AutoCallback',
    mixins: [
      transactionMixins, globalMixins, currencyMixins, autoCallbackMixins
    ],
    components: {
      ValidationProvider,
      ValidationObserver,
      ManualMatchForm,
    },
    mounted() {
      this.$watch(
        (vm) => [ vm.options ],
        () => { this.fetch(); },
        { immediate: true }
      );
    },
    computed: {
      isSms () {
        return this.$route.params.type === 'sms';
      },
      isDb () {
        return this.$route.params.type === 'db';
      },
      curPageTitle () {
        if (this.$route.params.type === 'sms') {
          return 'auto_callback_sms';
        }
        else if (this.$route.params.type === 'db') {
          return 'auto_callback_db';
        }
        else {
          return 'auto_callback'
        }
      },
      items () {
        return [
          {
            name: this.$t('pending'),
            route: {name: 'task', params: { status: 'pending', type: 'topup' }}
          },
          {
            name: this.$t('completed'),
            route: {name: 'task', params: { status: 'complete', type: 'topup' }}
          },
          {
            name: this.$t('auto_callback_sms'),
            route: { name: 'autoCallback', params: { type: 'sms' }}
          },
          {
            name: this.$t('auto_callback_db'),
            route: { name: 'autoCallback', params: { type: 'db' }}
          },
        ];
      },
      ...mapGetters({
        downloadLocale: 'lang/locale',
        user: 'auth/user',
        isRoot: 'auth/isRoot',
        isManager: 'auth/isManager',
        isCsAgent: 'auth/isCsAgent',
        isCsAgentBot: 'auth/isCsAgentBot',
        isReporter: 'auth/isReporter',
      }),
      headers () {
        const smsArr = [
          { 
            text: this.$t('auto_callback_sms_id'), 
            align: 'left', 
            value: 'sms_id', 
            sortable: false 
          },
          { 
            text: this.$t('auto_callback_sms_time'), 
            align: 'left', 
            value: 'sms_time', 
            sortable: false 
          },
          // TODO: Have to show status because just the txn cannot know it
          // is currently under processing, or success mapped, or fail mapped
          { text: this.$t('to_bankcode'), value: 'bank_code', sortable: false },
          { text: this.$t('to_bankflag'), value: 'bank_flag', sortable: false },
          { text: this.$t('to_cardname'), value: 'card_name', sortable: false },
          // { text: this.$t('to_cardname'), value: 'currency', sortable: false },
          {
            text: this.$t('to_cardnumber'), 
            value: 'card_number', 
            sortable: false 
          },
          { 
            text: this.$t('currency'), 
            value: 'currency_code', 
            sortable: false 
          },
          { text: this.$t('amount'), value: 'amount', sortable: false },
          { 
            text: this.$t('auto_callback_sms_sender'), 
            align: 'left', 
            value: 'sms_from', 
            sortable: false 
          },
          { 
            text: this.$t('auto_callback_sms_receiver'), 
            align: 'left', 
            value: 'sms_phone_no', 
            sortable: false 
          },
          { 
            text: this.$t('auto_callback_mapped_txn'), 
            align: 'left', 
            value: 'cmp_transaction_number', 
            sortable: false 
          },
          { text: this.$t('actions'), value: 'action', sortable: false },
        ];
        const dbArr = [
          { 
            text: this.$t('auto_callback_scdb_id'), 
            align: 'left', 
            value: 'db_id', 
            sortable: false 
          },
          { 
            text: this.$t('auto_callback_scdb_time'), 
            align: 'left', 
            value: 'trans_time', 
            sortable: false 
          },
          { text: this.$t('to_bankcode'), value: 'bankcode', sortable: false },
          { text: this.$t('to_bankflag'), value: 'bankflag', sortable: false },
          { text: this.$t('to_cardname'), value: 'cardname', sortable: false },
          {
            text: this.$t('to_cardnumber'), 
            value: 'account_no', 
            sortable: false 
          },
          { text: this.$t('currency'), value: 'currency', sortable: false },
          { text: this.$t('amount'), value: 'amount', sortable: false },
          { 
            text: this.$t('auto_callback_mapped_txn'), 
            align: 'left', 
            value: 'cmp_transaction_number', 
            sortable: false 
          },
          { text: this.$t('actions'), value: 'action', sortable: false },
        ];

        return this.isSms ? smsArr : dbArr;
      },
    },
    watch: {
      '$route.params.type': function () {
        this.resetOptions();
      },
      deep: true,
    },
    data: function() {
      return {
        pageInput: '',
        page: 1,
        totalData: 0,
        numberOfPages: 0,
        options: {
          page: 1,
          itemsPerPage: 50,
          mustSort: false,
        },
        loading: false,
        rowsPerPageItem: [50,100,200],
        dialog: false,
        busy: false,
        fromDate: null,
        toDate: null,
        search: '',
        dataList: [],
        editedItem: null,
      }
    },
    methods: {
      fetch () {
        const _this = this;
        
        const { page, itemsPerPage } = this.options;
        let pageNumber = page;

        let endpoint = '';
        let autoCallbackType = undefined;

        if (this.isSms) {
          endpoint = '/app/api/sms'
          autoCallbackType = this.AutoCallbackType.SMS;
        } 
        else if (this.isDb) {
          endpoint = '/app/api/scdb'
          autoCallbackType = this.AutoCallbackType.SCDB;
        }
        else {
          return
        }

        let params = {
          params: {
            page: pageNumber,
            count: itemsPerPage,
          }
        };

        // if (this.fromDate) {
        //   const fromDate = Date.parse(this.fromDate);

        //   if (!isNaN(fromDate)) {
        //     params.params.fromDate = fromDate;
        //   }
        // }
        // if (this.toDate) {
        //   const toDate = Date.parse(this.toDate);

        //   if (!isNaN(toDate)) {
        //     params.params.toDate = toDate;
        //   }
        // }
        // if (this.search) {
        //   params.params.search = this.search;
        // }

        this.busy = true;
        this.loading = true;

        axios
          .get(endpoint, params)
          .then(function (response) {
            _this.dataList = response.data.data.data;
            _this.totalData = response.data.data.total;
            _this.numberOfPages = response.data.data.total_page;

            // Add in general type
            
            _this.dataList.forEach(
              x => { x._type = autoCallbackType; }
            );
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
            _this.loading = false;
          });
      },
      viewItem (_type, id) {
        let item = undefined;
        
        if (_type === this.AutoCallbackType.SMS) {
          item = this.dataList.find(x => x.sms_id === id);
        }
        else if (_type === this.AutoCallbackType.SCDB) {
          item = this.dataList.find(x => x.db_id === id);
        }

        if (item) {
          this.editedItem = item;
          this.dialog = true;
        }
      },
      successManualMatch () {
        this.dialog = false;
        this.fetch();
      },
      closeManualMatch () {
        this.dialog = false;
      },
      resetOptions () {
        this.page = 1;
        this.totalData = 0;
        this.numberOfPages = 0;
        this.options = {
          page: 1,
          itemsPerPage: 50,
          mustSort: false,
        };
      },
      jumpToPage () {
        this.$refs.pageInputObserver.validate().then(result => {
          if (result && this.pageInput) {
            this.page = this.pageInput;
            this.pageInput = '';
          }
        });
      },
    },
  }
</script>

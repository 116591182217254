export const Sts = {
    PRC_ERROR: 0,
    MATCH_NONE: 1,
    MATCH_TOO_MANY: 2,
    MATCH_ONE_PROCESSING: 3,
    MATCH_ONE_SUCCESS: 4,
    MATCH_ONE_FAIL: 5,
};

export default {
    Sts,
};
<template>
  <v-card>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-center">
      <v-icon x-large color="yellow darken-2">mdi-alert</v-icon>
    </v-card-text>

    <v-card-text class="red--text text-center">
      {{ $t('please_double_check_transaction_amount') }}
    </v-card-text>
    <v-card-text class="red--text text-center">
      {{ $t('amount') }}: {{amount}}
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary darken-1" text @click="cancel">{{ $t('cancel') }}</v-btn>
      <v-btn color="success" @click="approve">{{ $t('confirm') }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    name: 'TrxApproveForm',
    props: ['amount'],
    methods: {
      approve () {
        this.$emit('approve');
      },
      cancel () {
        this.$emit('cancel');
      }
    },
  }
</script>

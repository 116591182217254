<template>
  <v-container fluid class="pa-4">
    <v-card>
      <v-toolbar
        color="primary"
        dark
        flat
      >
        <v-toolbar-title>
          {{$t('task')}}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!--        <v-btn icon :to="{name: 'contact.insert'}">-->
        <!--          <v-icon>mdi-plus-circle</v-icon>-->
        <!--        </v-btn>-->

        <v-btn v-if="isRoot || isManager || isReporter" icon @click="downloadTrx">
          <v-icon>mdi-download</v-icon>
<!--          <v-icon>mdi-dots-vertical</v-icon>-->
        </v-btn>

        <template v-if="$route.params.status !== 'unassigned'" v-slot:extension>
          <v-tabs
            align-with-title
            background-color="transparent"
          >
            <v-tabs-slider color="white"></v-tabs-slider>

            <v-tab
              v-for="(item, index) in items" :to="item.route" :key="index">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <div>
        <v-card-title>
          {{$t('transaction')}}
          <v-spacer></v-spacer>
          <v-row>
            <v-col>
              <v-datetime-picker
                v-model="fromDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_from')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="fetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col>
              <v-datetime-picker
                v-model="toDate"
                :text-field-props="{prependIcon: 'mdi-calendar'}"
                :label="$t('date_to')"
                :ok-text="$t('confirm')"
                :clear-text="$t('clear')"
                @input="fetch()"
              >
                <template v-slot:dateIcon>
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template v-slot:timeIcon>
                  <v-icon>mdi-clock</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
            <v-col v-if="$route.params.type !== 'adjust'">
              <v-select
                :disabled="trxFilterOptions.length === 0"
                item-text="text"
                item-value="code"
                v-model="trxFilter"
                :items="trxFilterOptions"
                :label="$t('Filter')"
                clearable
              ></v-select>
            </v-col>
            <v-col>
              <v-combobox
                :label="$t('currency')"
                :items="CurrencyList"
                item-text="currency_code"
                item-value="currency_code"
                :return-object="false"
                v-model="currencyFilter"
                clearable
                @change="fetch()"
              >
              </v-combobox>
            </v-col>
            <v-col>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                @click:append="fetch()"
                @keydown.enter="fetch()"
                :label="$t('search')"
                clearable
              ></v-text-field>
            </v-col>
            <v-col v-if="$route.params.type === 'adjust' && isRoot">
              <v-dialog v-model="showAdjustDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" dark v-on="on" class="float-right mt-3">{{$t('new_adjust')}}</v-btn>
                </template>
                <company-adjustment-form @success="createAdjustSuccess" @close="showAdjustDialog=false"></company-adjustment-form>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-title>

        <v-data-table
          :headers="headers"
          :items="transactionList"
          :page="page"
          :pageCount="numberOfPages"
          :options.sync="options"
          :multi-sort="true"
          :must-sort="false"
          :footer-props="{
            'show-current-page': true,
            'items-per-page-options': rowsPerPageItem,
            'show-first-last-page': true,
          }"
          :server-items-length="totalTransactions"
          :loading="loading"
        >
          <template v-slot:footer.prepend>
            <validation-observer ref="pageInputObserver" v-slot="{ validate, reset }">
              <validation-provider name="page" :rules="{integer: true, min_value: 1, max_value: numberOfPages}" v-slot="{ errors }">
                <v-text-field
                  v-model.number="pageInput"
                  type="number"
                  append-outer-icon="mdi-chevron-right-circle-outline"
                  @click:append-outer="jumpToPage"
                  :label="$t('Go to page')"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </validation-observer>
          </template>
          <template v-slot:top>
            <v-dialog v-model="dialog" max-width="500px">
              <!--            <template v-slot:activator="{ on }">-->
              <!--              <v-btn color="primary" dark class="mb-2" v-on="on">{{$t('new_transaction')}}</v-btn>-->
              <!--            </template>-->
              <task-form
                :form-title="formTitle" :is-editable="isEditable"
                :edited-item="editedItem" :edited-form="editedForm" :edited-index="editedIndex"
                :is2fa-req-submitted="is2faReqSubmitted"
                @fetch="fetch" @close="close" @refresh="viewItem"
                @2fa-submitted="is2faReqSubmitted = true"
                :viewed-at="viewedAt"
                :revert-rejected-trx="revertTrxFormDialog"
              ></task-form>
            </v-dialog>
          </template>
          <template v-slot:item.client_id="{ item }">
            <router-link :to="{name: 'contact.client', query: {q: item.client_id.toString()}}">{{item.client_id}}</router-link>
          </template>
          <template v-slot:item.cs_agent_b_id="{ item }">
            <router-link :to="{name: 'contact.staff', query: {q: item.cs_agent_b_id ? item.cs_agent_b_id.toString() : ''}}">
              {{item.cs_agent_b_id ? item.cs_agent_b_id : ''}}
            </router-link>
          </template>
          <template v-slot:item.bank="{ item }">
<!--            {{ item.bank.bank }} {{item.bank.province}}{{item.bank.city}}{{item.bank.branch}}-->
            {{ item.bank.bank }}
          </template>
          <template v-slot:item.amount="{ item }">
            {{ item.amount ? item.amount.toLocaleString(undefined, {minimumFractionDigits: 2}) : '-'}}
          </template>
          <template v-slot:item.commission="{ item }">
            {{ item.commission ? item.commission.toLocaleString(undefined, {minimumFractionDigits: 2}) : '-' }}
          </template>
          <template v-slot:item.net_amount="{ item }">
            {{ item.net_amount ? item.net_amount.toLocaleString(undefined, {minimumFractionDigits: 2}) : '-' }}
          </template>
          <template v-slot:item.creation_time="{ item }">
            {{ new Date(item.creation_time).toLocaleString() }}
          </template>
          <template v-slot:item.paytype="{ item }">
            {{ $t(item.paytype) }}
          </template>
          <template v-slot:item.pay_channel="{ item }">
            {{ $t(item.pay_channel) }}
          </template>
          <template v-slot:item.transaction_type="{ item }">
            {{ $t(getKeyByValue(TransactionType, item.transaction_type)) }}
          </template>
          <template v-slot:item.transaction_status="{ item }">
            <span :class="getStatusColor(item.transaction_status, item.assignee)">
              {{ getStatusType(item.transaction_status, item.assignee) }}
            </span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              v-if="(
                isCsAgent && item.transaction_status === TransactionStatus.PENDING && !item.assignee
              ) || (
                isCsAgentBot && !item.assignee && item.collectb &&
                !(item.collectb.sts === CollectBotSts.COMPLETED ||
                item.collectb.sts === CollectBotSts.REJECTED)
              )"
              small
              class="mr-2"
              @click="acceptTask(item)"
            >
              mdi-file-check
            </v-icon>
            <v-icon
              v-else
              small
              class="mr-2"
              @click="viewItem(item.transaction_number)"
            >
              mdi-file-find
            </v-icon>
            <v-icon
              v-if="
                isCsAgent && 
                (
                  item.transaction_type === TransactionType.TOP_UP ||
                  item.transaction_type === TransactionType.COLLECT
                ) &&
                item.transaction_status === TransactionStatus.REJECTED &&
                item.completion_type 
                  === TransactionCompletionType.TIMEOUT_AUTO_REJ
              "
              small
              class="mr-2"
              @click="openRevertTrxForm(item.transaction_number)"
            >
              mdi-undo-variant
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="fetch()">{{$t('refresh')}}</v-btn>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios';
  import { mapGetters } from 'vuex';
  import transactionMixins from '../../mixins/transactionMixins';
  import globalMixins from '../../mixins/globalMixins';
  import currencyMixins from '../../mixins/currencyMixins'
  import { integer, min_value, max_value } from 'vee-validate/dist/rules';
  import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
  import FileDownload from 'js-file-download';
  import CompanyAdjustmentForm from "./CompanyAdjustmentForm";
  import TaskForm from "./TaskForm";
  import {CollectBotSts} from "../../constants/transaction";

  extend('integer', integer);
  extend('min_value', min_value);
  extend('max_value', max_value);

  export default {
    name: 'Task',
    mixins: [transactionMixins, globalMixins, currencyMixins],
    components: {
      CompanyAdjustmentForm,
      TaskForm,
      ValidationProvider,
      ValidationObserver,
    },
    mounted() {
      // this.fetch();
      // topUpAssignment: function() {
      //   //eslint-disable-next-line
      //   console.log('topUpAssignment');
      //   this.fetch();
      // },
      // withdrawalAssignment: function() {
      //   //eslint-disable-next-line
      //   console.log('withdrawalAssignment');
      //   this.fetch();
      // },
      // unassignedTopUpTasks: function() {
      //   //eslint-disable-next-line
      //   console.log('unassignedTopUpTasks');
      //   this.fetch();
      // },
      // unassignedWithdrawalTasks: function() {
      //   //eslint-disable-next-line
      //   console.log('unassignedWithdrawalTasks');
      //   this.fetch();
      // },

      this.fetchCurrency();

      this.$watch(
        (vm) => [
          vm.topUpAssignment, vm.withdrawalAssignment, vm.unassignedTopUpTasks, vm.unassignedWithdrawalTasks, vm.options
        ],
        () => {
          //eslint-disable-next-line
          //console.log('watcher');
          this.fetch();
        },
        {
          immediate: true,
          // deep: true,
        }
      );
    },
    computed: {
      isEditable () {
        return (this.isCsAgent || this.isCsAgentBot) && this.$route.params.status === 'pending'
      },
      isCompleted () {
        return this.$route.params.status === 'complete'
      },
      items () {
        if (this.$route.params.type === 'adjust') {
          return [
            {
              name: this.$t('completed'),
              route: {name: 'task', params: { status: 'complete', type: this.$route.params.type}}
            }
          ];
        } else {
          return [
            {
              name: this.$t('pending'),
              route: {name: 'task', params: { status: 'pending', type: this.$route.params.type}}
            },
            {
              name: this.$t('completed'),
              route: {name: 'task', params: { status: 'complete', type: this.$route.params.type}}
            },
            ...(
              (this.isRoot || this.isManager || this.isCsAgent)
              ? [
                {
                  name: this.$t('auto_callback_sms'),
                  route: { name: 'autoCallback', params: { type: 'sms' }}
                },
                {
                  name: this.$t('auto_callback_db'),
                  route: { name: 'autoCallback', params: { type: 'db' }}
                },
              ]
              : []
            )
          ];
        }
      },
      ...mapGetters({
        downloadLocale: 'lang/locale',
        user: 'auth/user',
        autoAssignMode: 'auth/autoAssignMode',
        isRoot: 'auth/isRoot',
        isManager: 'auth/isManager',
        isCsAgent: 'auth/isCsAgent',
        isCsAgentBot: 'auth/isCsAgentBot',
        isReporter: 'auth/isReporter',
        topUpAssignment: 'auth/topUpAssignment',
        withdrawalAssignment: 'auth/withdrawalAssignment',
        unassignedTopUpTasks: 'auth/unassignedTopUpTasks',
        unassignedWithdrawalTasks: 'auth/unassignedWithdrawalTasks',
        editingTask: 'auth/editingTask',
      }),
      headers () {
        if (this.$route.params.type === 'adjust') {
          return [
            {text: this.$t('creation_time'), align: 'left', value: 'creation_time',},
            { text: this.$t('transaction_number'), value: 'transaction_number'},
            { text: this.$t('client_id'), value: 'client_id'},
            { text: this.$t('Client Name'), value: 'client_name'},
            { text: this.$t('adj_txn'), value: 'adj_txn'},
            { text: this.$t('adj_client_order_id'), value: 'adj_client_order_id'},
            { text: this.$t('transaction_type'), value: 'transaction_type', sortable: false },
            { text: this.$t('currency'), value: 'currency_code', sortable: false },
            { text: this.$t('amount'), value: 'amount', sortable: false },
            { text: this.$t('commission'), value: 'commission', sortable: false },
            { text: this.$t('transaction_status'), value: 'transaction_status', sortable: false },
            { text: this.$t('remark'), value: 'remark.user', sortable: false },
            { text: this.$t('actions'), value: 'action', sortable: false },
          ];
        }
        let arr = [
          {text: this.$t('creation_time'), align: 'left', value: 'creation_time',},
          { text: this.$t('transaction_number'), value: 'transaction_number'},
          { text: this.$t('client_order_id'), value: 'client_order_id'},
          { text: this.$t('client_user_id'), value: 'client_user_id'},
          { text: this.$t('paytype'), value: 'paytype', sortable: false },
          { text: this.$t('transaction_type'), value: 'transaction_type', sortable: false },
          { text: this.$t('currency'), value: 'currency_code', sortable: false },
          { text: this.$t('amount'), value: 'amount', sortable: false },
          { text: this.$t('commission'), value: 'commission', sortable: false },
          { text: this.$t('net_amount'), value: 'net_amount', sortable: false },
          { text: this.$t('transaction_status'), value: 'transaction_status', sortable: false },
          { text: this.$t('actions'), value: 'action', sortable: false },
        ];
        if (this.$route.params.type === 'withdrawal') {
          arr.splice(4, 1, ...[
            {text: this.$t('pay_channel'), value: 'pay_channel', sortable: false},
            {text: this.$t('to_bankflag'), value: 'bank', sortable: false},
            {text: this.$t('to_cardnumber'), value: 'beneficiary_account', sortable: false},
            {text: this.$t('to_cardname'), value: 'beneficiary_name', sortable: false},
          ]);
          arr.splice(-5, 0, ...[
            { text: this.$t('withdrawal_currency'), value: 'payment_currency', sortable: false },
          ]);
          if (this.isCsAgent || this.isCsAgentBot) {
            arr.splice(-5, 1);
          }
        } else { //topup
          arr.splice(4, 0, ...[
            {text: this.$t('from_cardname'), value: 'beneficiary_name', sortable: false},
            {text: this.$t('to_bankflag'), value: 'card.bank_card_bank_code', sortable: false},
            {text: this.$t('to_cardname'), value: 'card.bank_card_name', sortable: false},
          ]);
        }
        // if (this.isManager || this.isRoot || this.isCsAgent || this.isReporter) {
        arr.splice(3, 0, ...[
          { text: this.$t('client_id'), value: 'client_id'},
          { text: this.$t('Client Name'), value: 'client_name'},
        ]);
        if (this.isRoot && this.$route.params.type === 'topup') {
          arr.splice(3, 0, ...[
            // { text: this.$t('cs_agent_id'), value: 'cs_agent_id'},
            { text: this.$t('cs_agent_b_id'), value: 'cs_agent_b_id'},
          ]);
        }
        // }
        return arr;
      },
      formTitle () {
        if (this.editedIndex === -1) {
          return this.$t('new_transaction')
        } else if (this.editedItem.transaction_type === this.TransactionType.TOP_UP || this.editedItem.transaction_type === this.TransactionType.COLLECT) {
          return this.$t('topup_details')
        } else if (this.editedItem.transaction_type === this.TransactionType.WITHDRAW || this.editedItem.transaction_type === this.TransactionType.PAY) {
          return this.$t('withdraw_details')
        } else {
          return this.$t('transaction_details')
        }
      },
      trxFilterOptions() {
        let options = [];
        if (this.$route.params.status === 'complete') {
          options = [
            {code: 'completed', text: this.$t('completed_trx')},
            {code: 'rejected', text: this.$t('rejected_trx')},
          ];
        }
        if (this.isRoot) {
          options.splice(0, 0, {code: 'me', text: this.$t('My Transactions')});
        }
        if ((this.isCsAgent || this.isCsAgentBot) && this.$route.params.status === 'pending') {
          options.splice(0, 0, {code: 'me', text: this.$t('My Task')});
        }
        return options;
      },
    },
    watch: {
      dialog (val) {
        val || this.close()
      },
      '$route.params.type': function () {
        this.resetOptions();
      },
      '$route.params.status': function () {
        this.resetOptions();
      },
      editingTask: function () {
        if (this.editingTask && this.editedItem && this.editingTask.transaction_number === this.editedItem.transaction_number) {
          // if (this.editingTask.collectb && this.editedItem.collectb && this.editingTask.collectb.auth_value) {
          //   this.editedItem.collectb.auth_value = this.editingTask.collectb.auth_value;
          // }
          this.viewItem(this.editingTask.transaction_number)
          // _this.editedItem = Object.assign({}, response.data.data);
          // _this.editedForm.amount = _this.editedItem.amount;
          // _this.dialog = true
        }
      },
      trxFilter () {
        this.resetOptions();
      },
      // options: {
      //   handler() {
      //     this.fetch();
      //   },
      // },
      deep: true,
    },
    data: function() {
      return {
        pageInput: '',
        showAdjustDialog: false,
        approveDialog: null,
        revertTrxFormDialog: false,
        page: 1,
        totalTransactions: 0,
        numberOfPages: 0,
        options: {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['creation_time'],
          sortDesc: [true],
          multiSort: true,
          mustSort: false,
        },
        loading: false,
        rowsPerPageItem: [50,100,200],
        dialog: false,
        busy: false,
        fromDate: null,
        toDate: null,
        search: '',
        trxFilter: '',
        currencyFilter: '',
        transactionList: [],
        editedIndex: -1,
        editedItem: {
          transaction_number: null,
          paytype: null,
          amount: 0,
          commission: 0,
          net_amount: 0,
          currency_code: this.$app_currency_code,
          transaction_type: null,
          client_id: null,
          cs_agent_id: null,
          transaction_status: null,
          creation_time: null,
          completion_time: null,
          time_taken: null,
          remark: {
            client: null,
          },
          bank: {
            bank: null,
            branch: null,
            city: null,
            country: null,
            province: null,
          },
          collectb : {
            sts: null,
            bank_id: null,
            bank_name: null,
            username: null,
            password: null,
            auth_method: null,
            auth_hint: null,
            auth_value: null,
          },
          collectb_sts: null,
          beneficiary_account: null,
          beneficiary_name: null,
          assignee: null,
          receipt: null,
        },
        defaultItem: {
          transaction_number: null,
          paytype: null,
          amount: 0,
          commission: 0,
          net_amount: 0,
          currency_code: this.$app_currency_code,
          transaction_type: null,
          client_id: null,
          cs_agent_id: null,
          transaction_status: null,
          creation_time: null,
          completion_time: null,
          time_taken: null,
          remark: {
            client: null,
          },
          bank: {
            bank: null,
            branch: null,
            city: null,
            country: null,
            province: null,
          },
          collectb : {
            sts: null,
            bank_id: null,
            bank_name: null,
            username: null,
            password: null,
            auth_method: null,
            auth_hint: null,
            auth_value: null,
          },
          collectb_sts: null,
          beneficiary_account: null,
          beneficiary_name: null,
          assignee: null,
          receipt: null,
        },
        editedForm: {
          amount: null,
          act_bank_card_name: null,
          receipt: null,
          remark: null,
        },
        defaultForm: {
          amount: null,
          act_bank_card_name: null,
          receipt: null,
          remark: null,
        },
        is2faReqSubmitted: false,
        viewedAt: 0,
      }
    },
    methods: {
      downloadTrx () {
        this.fetch(true);
      },
      fetch (download = false) {
        const _this = this;
        
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        let pageNumber = page;

        let endpoint = '';
        if (this.$route.params.status === 'complete') {
          endpoint = '/app/api/transaction/completed'
        } else if (this.$route.params.status === 'pending') {
          endpoint = '/app/api/transaction/pending'
        } else if (this.$route.params.status === 'unassigned') {
          endpoint = '/app/api/transaction/unassigned'
        } else {
          return
        }

        let params = {
          params: {
            page: pageNumber,
            count: itemsPerPage,
            tz: new Date().getTimezoneOffset(),
            sortBy: sortBy,
            sortDesc: sortDesc,
          }
        };
        if (this.fromDate) {
          const fromDate = Date.parse(this.fromDate);

          if (!isNaN(fromDate)) {
            params.params.fromDate = fromDate;
          }
        }
        if (this.toDate) {
          const toDate = Date.parse(this.toDate);

          if (!isNaN(toDate)) {
            params.params.toDate = toDate;
          }
        }
        if (this.search) {
          params.params.search = this.search;
        }
        let trxType = null;
        if (this.$route.params.type === 'topup') {
          trxType = [this.TransactionType.TOP_UP, this.TransactionType.COLLECT];
        } else if (this.$route.params.type === 'withdrawal') {
          trxType = [this.TransactionType.WITHDRAW, this.TransactionType.PAY];
        } else if (this.$route.params.type === 'adjust') {
          trxType = [this.TransactionType.ADJUSTMENT];
        }
        if (trxType !== null) {
          params.params.type = trxType;
        }
        if (this.trxFilter === 'me') {
          params.params.me = true;
        } else if (this.trxFilter === 'completed') {
          params.params.status = this.TransactionStatus.COMPLETED;
        } else if (this.trxFilter === 'rejected') {
          params.params.status = this.TransactionStatus.REJECTED;
        }

        if (this.currencyFilter) {
          params.params.currency = this.currencyFilter;
        }

        if (download) {
          params.params.download = true;
          params.params.locale = this.downloadLocale;
          params.responseType = 'blob';
          axios.get(endpoint, params)
            .then((response) => {
              FileDownload(response.data, 'report.xlsx');
            })
            .catch(async function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: JSON.parse(await error.response.data.text()).message,
                color: 'error',
              })
            });
        } else {
          this.busy = true;
          this.loading = true;
          axios.get(endpoint, params)
            .then(function (response) {
              _this.transactionList = response.data.data.data;
              _this.totalTransactions = response.data.data.total;
              _this.numberOfPages = response.data.data.total_page;
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
              _this.loading = false;
            });
        }
      },
      viewItem (id) {
        this.viewedAt = Date.now();
        this.editedIndex = 0;
        this.busy = true;
        const _this = this;
        axios.get(`/app/api/transaction/${id}`)
          .then(function (response) {
            _this.editedItem = Object.assign({}, response.data.data);
            _this.editedForm.amount = _this.editedItem.amount;
            _this.is2faReqSubmitted = _this.editedItem.collectb && _this.editedItem.collectb.auth_method !== null
            if (_this.editedItem.collectb_sts === CollectBotSts.REJECTED) {
              _this.editedForm.remark = _this.$t('auto_collect_rejected');
            }
            _this.dialog = true
          })
          .catch(function (error) {
            _this.$store.dispatch('snackbar/openSnackbar', {
              message: error.response.data.message,
              color: 'error',
            })
          })
          .finally(function () {
            _this.busy = false;
          })
      },
      acceptTask (trx) {
        if (confirm(this.$t('accept_task_confirmation'))) {
          this.busy = true;
          const _this = this;
          axios.post(`/app/api/transaction/${trx.transaction_number}/acquire`)
            .then(function () {
              let trxType = 'all';
              if (trx.transaction_type === _this.TransactionType.TOP_UP || trx.transaction_type === _this.TransactionType.COLLECT) {
                trxType = 'topup';
              } else if (trx.transaction_type === _this.TransactionType.WITHDRAW || trx.transaction_type === _this.TransactionType.PAY) {
                trxType = 'withdrawal';
              }

              // _this.$store.dispatch('auth/addAssignment', trx);
              _this.$store.dispatch('auth/removeUnassignedTask', trx.transaction_number);

              _this.$router.push({name: 'task', params: {
                status: 'pending',
                type: trxType,
              }});
            })
            .catch(function (error) {
              _this.$store.dispatch('snackbar/openSnackbar', {
                message: error.response.data.message,
                color: 'error',
              })
            })
            .finally(function () {
              _this.busy = false;
            })
          }
      },
      close () {
        this.dialog = false;
        this.revertTrxFormDialog = false;
        // setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedForm = Object.assign({}, this.defaultForm);
        this.editedIndex = -1;
        // }, 300)
      },
      resetOptions () {
        this.page = 1;
        this.totalTransactions = 0;
        this.numberOfPages = 0;
        this.options = {
          page: 1,
          itemsPerPage: 50,
          sortBy: ['creation_time'],
          sortDesc: [true],
          multiSort: true,
          mustSort: false,
        };
        // this.trxFilter = '';
        // this.search = '';
      },
      createAdjustSuccess () {
        this.showAdjustDialog = false;
        this.fetch();
      },
      openRevertTrxForm (id) {
        this.revertTrxFormDialog = true;
        this.viewItem(id);
      },
      jumpToPage () {
        this.$refs.pageInputObserver.validate().then(result => {
          if (result && this.pageInput) {
            this.page = this.pageInput;
            this.pageInput = '';
          }
        });
      },
    },
  }
</script>

export const PayTypeEnum = {
  P2P: 'p2p',
};

export const PayType = [
  {code: 'remit', name: '银行卡转账'},
  {code: 'alipay', name: '支付宝'},
  {code: 'online_transfer', name: '快捷支付'},
  {code: 'remit_qr', name: 'QR 银行卡转账'},
  {code: 'p2p', name: 'P2P'},
  {code: 'momopay', name: 'MOMOPAY'},
  {code: 'bbstart', name: 'BBSTART'},
  {code: 'zalopay', name: 'ZALOPAY'},
  {code: 'va_offline', name: 'VA Offline'},
  {code: 'ovo_wallet', name: 'OVO Wallet'},
  {code: 'gopay', name: 'GOPAY'},
  {code: 'dana', name: 'DANA'},
  {code: 'upi', name: 'UPI'},
  {code: 'netbanking', name: 'NETBANKING'},
  {code: 'remit_idr_low_amt', name: 'REMIT_IDR_LOW_AMT'},
  {code: 'remit_thb_low_amt', name: 'REMIT_THB_LOW_AMT'},
  {code: 'remit_jpy_low_amt', name: 'REMIT_JPY_LOW_AMT'},
  {code: 'remit_sgd_low_amt', name: 'REMIT_SGD_LOW_AMT'},
  {code: 'remit_qr_thb_low_amt', name: 'REMIT_QR_THB_LOW_AMT'},
  {code: 'thai_qr_cor', name: 'THAI_QR_COR'},
  {code: 'remit_qr_thb_low_amt_2', name: 'REMIT_QR_THB_LOW_AMT_2'},
  {code: 'true_money', name: 'TRUE_MONEY'},
  {code: 'remit_qr_idr_low_amt', name: 'REMIT_QR_IDR_LOW_AMT'},
  {code: 'remit_thb_low_amt_fafapay', name: 'REMIT_THB_LOW_AMT_FAFAPAY'},
  {code: 'remit_qr_thb_low_amt_fafapay', name: 'REMIT_QR_THB_LOW_AMT_FAFAPAY'},
  {code: 'remit_qr_thb_low_amt_fafa1', name: 'REMIT_QR_THB_LOW_AMT_FAFA1'},
  {code: 'remit_qr_thb_low_amt_fafa2', name: 'REMIT_QR_THB_LOW_AMT_FAFA2'},
  {code: 'online_transfer_myr_low_amt', name: 'ONLINE_TRANSFER_MYR_LOW_AMT'},
  {code: 'remit_qr_myr_low_amt', name: 'REMIT_QR_MYR_LOW_AMT'},
  {code: 'online_transfer_sgd_low_amt', name: 'ONLINE_TRANSFER_SGD_LOW_AMT'},
  {code: 'remit_qr_sgd_low_amt', name: 'REMIT_QR_SGD_LOW_AMT'},
  {code: 'remit_vnd_low_amt_fafapay', name: 'REMIT_VND_LOW_AMT_FAFAPAY'},
  {code: 'remit_qr_vnd_low_amt_fafapay', name: 'REMIT_QR_VND_LOW_AMT_FAFAPAY'},
  {code: 'remit_vnd_low_amt_smart', name: 'REMIT_VND_LOW_AMT_SMART'},
  {code: 'remit_qr_vnd_low_amt_smart', name: 'REMIT_QR_VND_LOW_AMT_SMART'},
  {code: 'momopay_smart', name: 'MOMOPAY_SMART'},
  {code: 'zalopay_smart', name: 'ZALOPAY_SMART'},
];

import axios from "axios";

let clientMixins = {
  props: ['editedItem'],
  data: function () {
    return {
      busy: false,
    }
  },
  methods: {
    updateUser () {
      this.busy = true;
      const _this = this;

      let reqBody = {
        email: this.editedItem.email,
        phone: {
          code: this.editedItem.phone_code,
          number: this.editedItem.phone_number,
        },
        display_name: this.editedItem.display_name,
        address: this.editedItem.address,

        ena_login_whitelist_ip: this.editedItem.ena_login_whitelist_ip,
        login_whitelist_ip: this.editedItem.login_whitelist_ip,
      };

      if (this.editedItem.user_type === this.UserType.CLIENT) {
        reqBody.client_name = this.editedItem.client_name;
        reqBody.trx_cfg = this.editedItem.trx_cfg;
        reqBody.assign_bankcard = this.editedItem.assign_bankcard;

        reqBody.cust_blacklist_ip = this.editedItem.cust_blacklist_ip;
        reqBody.cust_blacklist_client_user 
          = this.editedItem.cust_blacklist_client_user;
        reqBody.api_version = this.editedItem.api_version;
        reqBody.api_whitelist_ip = this.editedItem.api_whitelist_ip

        reqBody.ena_collect_trx_amt_adj = this.editedItem.ena_collect_trx_amt_adj;
        reqBody.ena_org_amt_comm_calc = this.editedItem.ena_org_amt_comm_calc;
        reqBody.ena_collect_tobankcode = this.editedItem.ena_collect_tobankcode;
        reqBody.ena_ignore_tp_reject_callback = this.editedItem.ena_ignore_tp_reject_callback;

      } else if (this.editedItem.user_type === this.UserType.CLIENT_CSA) {
        //client_csa
      }

      axios.put(`/app/api/client/${this.editedItem.user_id}`, reqBody)
        .then(function () {
          _this.fetch();
          _this.close()
        })
        .catch(function (error) {
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.busy = false;
        })
    },
    addUser () {
      this.busy = true;
      const _this = this;

      let reqBody = {
        username: this.editedItem.username,
        password: this.editedItem.password,
        email: this.editedItem.email,
        phone: {
          code: this.editedItem.phone_code,
          number: this.editedItem.phone_number,
        },
        display_name: this.editedItem.display_name,
        // status: this.editedItem.status,
        address: this.editedItem.address,
        ena_login_whitelist_ip: this.editedItem.ena_login_whitelist_ip,
        login_whitelist_ip: this.editedItem.login_whitelist_ip,
      };

      if (this.editedItem.user_type === this.UserType.CLIENT) {
        reqBody.user_type = 'client';
        reqBody.client_name = this.editedItem.client_name;
        reqBody.trx_cfg = this.editedItem.trx_cfg;
        reqBody.assign_bankcard = this.editedItem.assign_bankcard;

        reqBody.cust_blacklist_ip = this.editedItem.cust_blacklist_ip;
        reqBody.cust_blacklist_client_user 
          = this.editedItem.cust_blacklist_client_user;
        reqBody.api_version = this.editedItem.api_version;
        reqBody.api_whitelist_ip = this.editedItem.api_whitelist_ip

        reqBody.ena_collect_trx_amt_adj = this.editedItem.ena_collect_trx_amt_adj;
        reqBody.ena_org_amt_comm_calc = this.editedItem.ena_org_amt_comm_calc;
        reqBody.ena_collect_tobankcode = this.editedItem.ena_collect_tobankcode;
        reqBody.ena_ignore_tp_reject_callback = this.editedItem.ena_ignore_tp_reject_callback;
      } else if (this.editedItem.user_type === this.UserType.CLIENT_CSA) {
        reqBody.user_type = 'client_csa';
        reqBody.client_id = this.editedItem.client_id;
      }

      axios.post('/app/api/client', reqBody)
        .then(function () {
          _this.fetch();
          _this.close();
        })
        .catch(function (error) {
          // Show error.
          _this.$store.dispatch('snackbar/openSnackbar', {
            message: error.response.data.message,
            color: 'error',
          })
        })
        .finally(function () {
          _this.busy = false;
        })
    },
  }
}

export default clientMixins;

<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        {{$t(curPageData.title)}} ({{curPageData.id}})
      </span>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pb-0">
      <ValidationObserver ref="observer" v-slot="{ validate, reset }">
        <form v-on:submit.prevent="adjust">
          <v-row>
            <v-col cols="6">
              <v-text-field
                :label="$t('to_bankcode')"
                v-model="curPageData.bank_code"
                type="text"
                filled
                readonly
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('to_cardnumber')"
                v-model="curPageData.card_number"
                type="text"
                filled
                readonly
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('currency')"
                v-model="curPageData.currency_code"
                type="text"
                filled
                readonly
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                :label="$t('amount')"
                v-model="curPageData.amount"
                type="text"
                filled
                readonly
              />
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col cols="12">
              <ValidationProvider 
                name="transaction_number" 
                rules="required|numeric" 
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors"
                  :label="$t('transaction_number')"
                  name="transaction_number"
                  type="text"
                  v-model.number="editedForm.transaction_number"
                />
              </ValidationProvider>
            </v-col>
            <v-col cols="12">
              <ValidationProvider 
                name="client_order_id" 
                rules="required" 
                v-slot="{ errors }"
              >
                <v-text-field
                  :error-messages="errors"
                  :label="$t('client_order_id')"
                  name="client_order_id"
                  type="text"
                  v-model="editedForm.client_order_id"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </v-card-text>
    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn 
        color="primary darken-1" 
        text 
        @click="$emit('close')"
      >
        {{$t('cancel')}}
      </v-btn>
      <v-btn color="primary" @click="manualMatch">{{$t('confirm')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import axios from 'axios'
  import { mapGetters } from 'vuex'
  import { 
    required, min_value, max_value, numeric 
  } from 'vee-validate/dist/rules';
  import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
  import contactMixins from '../../mixins/contactMixins'
  import currencyMixins from '../../mixins/currencyMixins'
  import autoCallbackMixins from '../../mixins/autoCallbackMixins'

  extend('required', required);
  extend('min_value', min_value);
  extend('max_value', max_value);
  extend('numeric', numeric);

  export default {
    name: 'ManualMatchForm',
    props: ['editedItemType', 'editedItem', 'dialog'],
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    mixins: [contactMixins, currencyMixins, autoCallbackMixins],
    computed: {
      ...mapGetters({
        user: 'auth/user',
      }),
      curPageData () {
        if (this.editedItemType === this.AutoCallbackType.SMS) {
          return {
            title: 'auto_callback_sms_manual_match',
            id: this.editedItem.sms_id,
            bank_code: this.editedItem.bank_code,
            card_number: this.editedItem.card_number,
            currency_code: this.editedItem.currency_code,
            amount: this.editedItem.amount,
          };
        }
        else if (this.editedItemType === this.AutoCallbackType.SCDB) {
          return {
            title: 'auto_callback_scdb_manual_match',
            id: this.editedItem.db_id,
            bank_code: this.editedItem.bankcode,
            card_number: this.editedItem.account_no,
            currency_code: this.editedItem.currency,
            amount: this.editedItem.amount,
          };
        }
        else {
          return undefined;
        }
      },
    },
    data: function() {
      return {
        editedForm: {
          transaction_number: '', 
          client_order_id: '', 
        },
        defaultForm: {
          transaction_number: '', 
          client_order_id: '', 
        },
        busy: false,
      }
    },
    watch: {
      dialog (val) {
        if (!val) {
          this.dialogClose();
        }
      }
    },
    methods: {
      async manualMatch() {
        const _this = this;

        this.$refs.observer.validate().then(result => {
          if (result) {
            _this.busy = true;

            let postData = {
              transaction_number: _this.editedForm.transaction_number,
              client_order_id: _this.editedForm.client_order_id,
            };

            let url = undefined;

            if (this.editedItemType === this.AutoCallbackType.SMS) {
                url = `/app/api/sms/${_this.editedItem.sms_id}/manualmatch`;
            }
            else if (this.editedItemType === this.AutoCallbackType.SCDB) {
                url = `/app/api/scdb/${_this.editedItem.db_id}/manualmatch`;
            }
            else {
              return;
            }

            axios
              .post(url, postData)
              .then(function (response) {
                _this.$store.dispatch('snackbar/openSnackbar', {
                  message: response.data.message,
                  color: 'success',
                });
                _this.success();
              })
              .catch(function (error) {
                _this.$store.dispatch('snackbar/openSnackbar', {
                  message: error.response.data.message,
                  color: 'error',
                })
              })
              .finally(function () {
                _this.busy = false;
              });
          }
        });
      },
      success () {
        this.$emit('success');
        this.editedForm = Object.assign({}, this.defaultForm);
        this.$refs.observer.reset();
      },
      dialogClose () {
        this.editedForm = Object.assign({}, this.defaultForm);
        this.$refs.observer.reset();
      }
    },
  }
</script>
